import StarRating from 'vue-star-rating';
import { routeToProduct } from '@/helpers';
import ImageCard from '@/components/@general/ImageCard/index.vue';

export default {
  name: 'ProductPopularCard',
  components: {
    ImageCard,
    StarRating
  },
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  computed: {},
  methods: { routeToProduct }
};
