import _ from 'lodash';

import ProductSlider from './components/ProductSlider/index.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

//---helpers
import { MEDIA } from '@/helpers/variables';
import { PRODUCT_SLIDER_ITEM_TYPE, PRODUCTS_ORDER_KEY, checkIfHasVideoUrl } from '@/helpers';

//---mixins
import { dynamicHead } from '@/mixins/dynamic-head.js';

//---components
import BreadcrumbsSimple from '@/components/@general/BreadcrumbsSimple/index.vue';
import IconHeart from '@/components/icons/IconHeart.vue';
import ProductTabs from '@/modules/Product/components/ProductTabs/index.vue';
import ProductPopularCard from '@/modules/Product/components/ProductPopularCard/index.vue';
import ProductSeries from '@/modules/Product/components/ProductSeries/index.vue';
import ProductInfo from '@/modules/Product/components/ProductInfo/index.vue';

import defaultImage from '@/assets/img/svg/default-image.svg';

export default {
  name: 'Product',
  mixins: [dynamicHead],
  components: {
    ProductInfo,
    ProductSeries,
    ProductPopularCard,
    ProductTabs,
    IconHeart,
    BreadcrumbsSimple,
    ProductSlider
  },
  data() {
    return {
      MEDIA,
      clientPrices: [],
      seriesProducts: [],
      popularProducts: [],
      selectedProductPrice: null,
      productSlides: [],
      isFavorite: false
    };
  },

  async created() {
    let { slug } = this.$route.params;
    await this.getProduct(slug);
    this._setMetaParams();
    await this._loadContent();

    let trackEvent = {
      content_type: 'product',
      currency: 'UAH',
      content_ids: this.productData.unauthorizedProductPriceId
    };

    if (this.productData.brand) {
      trackEvent.content_category = this.productData.brand.title;
    }
    if (this.productData.brandSeries) {
      trackEvent.content_category = this.productData.brandSeries.title;
    }
    trackEvent.content_name = this.productData.title;
    trackEvent.value = this.productData.productPrices.data[0].price;

    fbq('track', 'ViewContent', { ...trackEvent });
  },
  computed: {
    ...mapGetters({
      productLoading: 'product/productLoading',
      productData: 'product/product',
      favourites: 'favorites/whichList',
      productsWithDefaultPrices: 'basket/productsWithDefaultPrices',
      isAuthenticated: 'auth/isAuthenticated',
      appSize: 'app/size'
    }),
    breadcrumbsList() {
      return this.productData && this.productData.brand
        ? [
            {
              title: this.productData.brand.title,
              route: { name: 'series', params: { series: this.productData.brand.slug } }
            }
          ]
        : [];
    }
  },
  methods: {
    ...mapActions({
      addToFavorite: 'favorites/ADD_PRODUCT_TO_FAVOURITES',
      getProduct: 'product/GET_PRODUCT',
      removeFromFavorite: 'favorites/REMOVE_PRODUCT_IN_FAVORITE',
      getFavorites: 'favorites/GET_FAVORITES_LIST',
      fetchDefaultPrices: 'basket/GET_DEFAULT_PRICE_BASKET',

      getCatalogProducts: 'catalog/GET_CATALOG_PRODUCTS'
    }),
    ...mapMutations({
      changeLoginPopup: 'popups/CHANGE_LOGIN_POPUP'
    }),

    onUpdateProductSlider(selectedProductPrice) {
      let slides = [];
      let { images, image, image2, video } = this.productData;
      if (selectedProductPrice && selectedProductPrice.image) {
        slides.push({ link: selectedProductPrice.image, type: PRODUCT_SLIDER_ITEM_TYPE.IMAGE });
      }
      if (image && image.length) {
        slides.push({ link: image, type: PRODUCT_SLIDER_ITEM_TYPE.IMAGE });
      }
      if (image2 && image2.length) {
        slides.push({ link: image2, type: PRODUCT_SLIDER_ITEM_TYPE.IMAGE });
      }
      if (images && images.data.length) {
        let decoratedImages = images.data
          // remove with empty links
          .filter(({ link }) => link)
          // add type to every image
          .map((item) => ({
            ...item,
            type: checkIfHasVideoUrl(item.link) ? PRODUCT_SLIDER_ITEM_TYPE.VIDEO : PRODUCT_SLIDER_ITEM_TYPE.IMAGE
          }));

        slides = [...slides, ...decoratedImages];
      }
      if (video && video.length) {
        slides.push({ link: video, type: PRODUCT_SLIDER_ITEM_TYPE.VIDEO });
      }
      slides = _.uniqBy(slides, 'link');
      if (!slides.length) {
        slides = [{ link: defaultImage, type: PRODUCT_SLIDER_ITEM_TYPE.IMAGE }];
      }
      this.productSlides = slides;
    },

    async _loadContent() {
      let promiseCollection = [];

      promiseCollection.push(
        this.getCatalogProducts({
          page: 1,
          per_page: 4,
          sort_filter_type: PRODUCTS_ORDER_KEY.BESTSELLERS
        }).then((result) => {
          this.popularProducts = result.data;
        })
      );

      promiseCollection.push(
        this.fetchDefaultPrices({ ids: [this.productData.id] }).then(() => {
          this.productsWithDefaultPrices.forEach((product) => {
            this.clientPrices = product.productPrices.data;
          });
        })
      );

      if (this.productData.brand) {
        promiseCollection.push(
          this.getCatalogProducts({
            brand_slug: this.productData.brand.slug,
            page: 1,
            sort_filter_type: PRODUCTS_ORDER_KEY.NEWEST
          }).then((result) => {
            this.seriesProducts = result.data;
          })
        );
      }

      await Promise.all(promiseCollection);
    },

    _setMetaParams() {
      let { metaTitle, title, metaDescription, image } = this.productData;
      if (title || metaTitle)
        this.metaParams.title = `${metaTitle ? metaTitle : title}${this.$t('seo.metaTitleGeneral')}`;
      if (metaDescription) this.metaParams.description = metaDescription;
      if (image) this.metaParams.image = image;
      this.setMeta();
    },

    onChangeFavorite() {
      if (this.isAuthenticated) {
        const findItem = this.favourites.find((e) => e.productId === this.productData.id);
        if (findItem > -1) {
          this.removeFromFavorite(findItem.id).then(() => {
            this.getFavorites();
            this.isFavorite = false;
            this.$toasted.success(this.$t('toast.successRemoveFromFavorite'));
          });
        } else {
          this.addToFavorite({ product_id: this.productData.id }).then(() => {
            this.isFavorite = true;
            this.$toasted.success(this.$t('toast.successAddToFavorites'));
            this.getFavorites();
          });
        }
      } else {
        this.changeLoginPopup(true);
      }
    }
  }
};
